import {useAuth0} from "@auth0/auth0-react";
import {useMediaQuery} from "@mui/material";
import {isObject} from "lodash";
import {models} from 'powerbi-client';
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import DevReportForm from "../components/DevReportForm";
import EmbeddedReport from "../components/EmbeddedReport";
import ErrorMessage from "../components/ErrorMessage";
import NthDrawer from "../components/NthDrawer";
import '../components/styles/PBIEmbed.css'
import {useDatePickerContext} from "../Contexts/DateTimePickerProvider";
import {useJokeContext} from "../Contexts/JokeProvider";
import {usePbiContext} from "../Contexts/PbiContextProvider";
import {useToken} from "../Contexts/TokenProvider";
import {filterTemplate} from "../utils/PBI Utils/FilterUtils";
import {PBIReportURLSetter, urlLoad} from "../utils/PBI Utils/PBI_URL";
import LoadingCircle from "../components/LoadingCircle";
import {LoadedHandler, refreshReport, RenderedHandler} from "../utils/PBI Utils/ReportLifecycleHandlers";


/**
 * Embeds Power BI Report after obtaining a valid AccessToken from Power BI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PbiReport(props) {
    const pullerWidth = 32
    const {
        pdfConfig = {pdf: false},
        params
    } = props
    const {
        tokens,
        productPath,
        setTokenValues,
        paidMachines,
        loading,
        datasetIDs,
        reportIDs,
        badRequestCounter,
        devReport,
        setDevReport,
        devDataset,
        setDevDataset,
        setDevSubmit,
        embedType
    } = usePbiContext()
    const {user} = useAuth0()
    const {allowedCustomers, userRoles} = useToken()
    const nthUser = useMemo(() => userRoles.includes("SuperUser"), [userRoles])
    const {
        currentJoke,
        newJoke
    } = useJokeContext()
    const {
        setReport: setDateReport,
        setDatepickerOpen,
        setDates
    } = useDatePickerContext()

    const [refresh, setRefresh] = useState(true)

    /*** Memos and States ***/

        // PowerBI Report object (to be received via callback)
    const [report, setReport] = useState(undefined);
    const [isLoaded, setIsLoaded] = useState(false) //used to trigger useEffect, where async function can be def
    const [isRendered, setIsRendered] = useState(false) // same but for rendered
    const [renderCount, setRenderCount] = useState(0)
    const [firstLoad, setFirstLoad] = useState(true) // used to load from URL if first time loading report
    const reportToken = useMemo(() => tokens[productPath],
        [tokens, productPath, embedType])

    /*** Layout States ***/
    const mobileView = useMediaQuery(theme => theme.breakpoints.down('md'))
    const tabletView = useMediaQuery(theme => theme.breakpoints.down('lg'))
    const layoutType = useMemo(() => {
        if (mobileView) {
            return models.LayoutType.MobilePortrait
        }
        if (tabletView) {
            return models.LayoutType.MobileLandscape
        }
        return models.LayoutType.Custom
    }, [mobileView, tabletView])

    /** Styles **/
    const backgroundDivStyle = useMemo(() => ({
        position: 'absolute',
        left: pdfConfig.pdf ? 0 : pullerWidth,
        width: pdfConfig.pdf ? '100%' : `Calc(100% - ${pullerWidth}px)`
    }), [pdfConfig.pdf])

    const loadingCircleStyle = useMemo(() => ({
        position: 'fixed',
        top: "Calc(50vh - 100px)",
        left: "Calc(50vw - 100px)"
    }), [])

    /*** PBI Dataset and Report States ***/
    const datasets = useMemo(() => {
        let returnDatasets = isObject(datasetIDs) ? Object.keys(datasetIDs)
            .filter(x => datasetIDs[x]) : []
        returnDatasets = returnDatasets.map(x => x.toUpperCase())
        returnDatasets.sort((a, b) => a === "DEMO" ? -25 : a < b ? -1 : 1)
        return allowedCustomers.filter(dataset => returnDatasets.includes(dataset.toUpperCase()))
    }, [datasetIDs])
    const [currentDataset, setCurrentDataset] = useState(params.load('product', datasets[0]))
    const reports = useMemo(() => {
        // Check if reports have ids and if the current dataset supports it
        if (currentDataset && isObject(reportIDs) && datasetIDs[currentDataset]) {
            return Object.keys(datasetIDs).filter(x => {
                // filter out empty IDs
                return reportIDs[x] && datasetIDs[currentDataset]
            })
        }
        return []
    }, [currentDataset, datasetIDs, reportIDs])
    const [currentReport, setCurrentReport] = useState(reports[0])
    const loadingCircleRef = useRef()

    /*** Timezone States ***/
        // Get TimeZone Offset, construct timezone filter item
    const timezoneOffset = -(new Date().getTimezoneOffset()) / 60
    const [timezoneOffsetFilter] = useState(filterTemplate("TimezoneOffset", [timezoneOffset], "TimeZoneMapping", true, true))

    /*** Report Filters ***/
    const [locationFilter] = useState(null)
    const [timeFilterInput] = useState(null)
    const [islandFilter] = useState(null)
    const [lineFilter] = useState(null)
    const [macFilter] = useState(null)

    const paidFilter = useMemo(() => {
        if (nthUser) return undefined
        if (!(paidMachines && (paidMachines.hasOwnProperty(currentDataset?.toString())))) {
            return undefined
        }
        return filterTemplate("MACHINE", paidMachines[currentDataset], undefined, undefined, true)
    }, [currentDataset, paidMachines, nthUser])
    const filters = useMemo(() => [timezoneOffsetFilter, paidFilter],
        [islandFilter, lineFilter, locationFilter, macFilter, paidFilter])

    const filteredParams = useMemo(() => new urlLoad({
        ...Object.keys(params.url)
            .filter(x => x !== "product")
            .map(x => ({[x]: params.url[x]}))
    }), [params.url])

    /*** Dev Mode States ***/
    const isDev = useMemo(() => {
        if (!(user && user[`${window.location.origin}/app_metadata`])) {
            return false
        }
        return user[`${window.location.origin}/app_metadata`]?.devAdmin
    }, [user])
    const [devMode, setDevMode] = useState(false)

    /*** Refresh States ***/
    const [lastRefreshDateState, setLastRefreshDateState] = useState(new Date())
    const useLastRefreshDate = useCallback(getSetter => {
        if (!(getSetter === 'getSetter')) {
            return lastRefreshDateState
        }
        return setLastRefreshDateState
    }, [lastRefreshDateState, setLastRefreshDateState])

    // for automated refreshes, to check if really refreshed
    const [realRefresh, setRealRefresh] = useState(false)

    /*** useEffects ***/

    // useInterval(() => refreshReport(report, realRefresh, isRendered, setRealRefresh, setIsRendered), 5000)

    // Load product from URL parameters
    useEffect(() => {
        if (!currentDataset && params.load("product")) {
            setCurrentDataset(params.load("product", datasets[0]))
        } else if (!devMode && !params.load("product")) setCurrentDataset(datasets[0])
    }, [datasetIDs])

    // Load the first report if there's no current report
    useEffect(() => {
        if (!currentReport || !devMode) {
            setCurrentReport(reports[0])
        }
    }, [reports])

    // allow initializing when new report/ dataset is selected
    useEffect(() => {
        setRefresh(false)
        setIsLoaded(false)
        setIsRendered(false)
        setRenderCount(0)
        setCurrentReport(currentDataset)
    }, [currentDataset, currentReport])

    useEffect(() => {
        if (!refresh) setRefresh(true)
    }, [refresh])

    //refresh access token
    useEffect(() => {
        if (report !== undefined)
            report.setAccessToken(reportToken)
                .catch(e => console.log(e))
    }, [reportToken])

    // initialization block that runs after loaded, but before rendered
    useEffect(() => {
        LoadedHandler(isLoaded, firstLoad, filteredParams, params, filters, lastRefreshDateState, setLastRefreshDateState, timeFilterInput, setFirstLoad, report);
    }, [isLoaded, filters])

    //when rendered after the report is loaded
    useEffect(() => {
        RenderedHandler(isRendered, report, lastRefreshDateState, setLastRefreshDateState, setDatepickerOpen, setDates, useLastRefreshDate, newJoke);
    }, [isRendered, report])

    // sets report for datepicker
    useEffect(() => {
        setDateReport(report)
    }, [report, setDateReport])

    /*** Rendering Components ***/

    const drawer = useMemo(() => pdfConfig.pdf
            ? null
            : <NthDrawer products={datasets} selectedProduct={currentDataset} pullerProps={{width: pullerWidth}}
                         changeProduct={setCurrentDataset} product={currentDataset}/>
        , [currentDataset, datasets, pdfConfig.pdf])


    return <>
        <PBIReportURLSetter {...{
            currentReport,
            currentDataset,
            setCurrentDataset,
            setCurrentReport,
            report,
            isRendered,
            renderCount,
            isLoaded
        }}/>
        {drawer}
        <div style={backgroundDivStyle}>
            {!loading
                ? <>
                    {currentDataset && currentReport
                        ? <EmbeddedReport {...{
                                loading,
                                reportToken,
                                refresh,
                                currentReport,
                                currentDataset,
                                loadingCircleRef,
                                devMode,
                                reportIDs,
                                datasetIDs,
                                layoutType,
                                setIsLoaded,
                                setIsRendered,
                                setReport,
                                setRenderCount,
                                filters,
                                params,
                                loadingCircleStyle
                            }}/>
                        : <LoadingCircle loadingText="Loading Datasets" currentJoke={currentJoke}
                                         style={loadingCircleStyle}/>
                    }
                    {isDev
                        ? <DevReportForm{...{
                            devMode,
                            setDevReport,
                            setDevDataset,
                            setCurrentReport,
                            setCurrentDataset,
                            setTokenValues,
                            setDevMode,
                            devReport,
                            devDataset,
                            setDevSubmit,
                            reportIDs,
                            currentReport,
                            datasetIDs,
                            currentDataset
                        }}/>
                        : null
                    }
                </>
                : badRequestCounter < 5
                    ? <LoadingCircle loadingText="Loading Dashboard" currentJoke={currentJoke}
                                     style={loadingCircleStyle}/>
                    : <ErrorMessage message="Failed to load the reports"/>
            }
        </div>
    </>
}

//https://api.powerbi.com/metadata/cluster